@tailwind base;
@tailwind components;
@tailwind utilities;

/* Active link menu with enhanced accessibility */
.active {
  @apply bg-white rounded-md w-full shadow-lg shadow-red-500/40 ring-2 ring-red-500 ring-opacity-50;
}

/* Google font - Poppins with variable weights */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* Base styles with improved accessibility */
* {
  box-sizing: border-box;
  padding: 0;
  cursor: none;
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* Enhanced focus styles for accessibility */
:focus {
  outline: 2px solid #ec0101;
  outline-offset: 2px;
}

:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  outline: 2px solid #ec0101;
  outline-offset: 2px;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Prevent headings from being hidden under fixed header */
}

/* Improved typography */
body,
button,
input,
textarea {
  font-size: 1rem;
  cursor: none;
  line-height: 1.5;
}

body {
  background-color: #fff;
  padding-right: 0px !important;
  color-scheme: light dark;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Better typography hierarchy */
h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.2;
}

h2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.3;
}

h3 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Clean lists */
ul {
  list-style: none;
}

/* Clean links with transitions */
a {
  text-decoration: none;
  cursor: none;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/* Button styles with transitions */
button {
  cursor: none;
  border: none;
  outline: none;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
}

button:active {
  transform: scale(0.98);
}

/* Ensure inputs are above other elements */
input,
textarea {
  z-index: 1;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

/* Responsive images */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Improved scrollbar styles */
::-webkit-scrollbar {
  width: 0.6rem;
  background: hsl(355, 8%, 44%, 0.3);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: hsl(355, 32%, 17%);
  border-radius: 1rem;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(355, 50%, 25%);
  border: 2px solid transparent;
  background-clip: content-box;
}

/* Enhanced animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease forwards;
}

.animate-slideUp {
  animation: slideInUp 0.5s ease forwards;
}

/* Motion-reduced experience */
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Dark mode improvements */
.dark body {
  background-color: hsl(355, 100%, 7%);
  color: #ffffff;
}

.light body {
  background-color: hsl(219, 100%, 99%);
  color: #1f2229;
}

/* Better focus styles based on theme */
.dark :focus-visible {
  outline-color: #ff8a18;
}

.light :focus-visible {
  outline-color: #ec0101;
}
